import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSearch, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-scroll';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-gradient-to-r from-[#FD5F20] via-[#FD5F20] to-[#FD5F20] shadow-lg fixed top-0 left-0 right-0 z-50 h-20 ">

      <div className="container mx-auto px-4 ">
        <div className="flex justify-between items-center py-4 text-white">
          <div className='mb-4'>
            <h1 className="text-4xl font-bold text-gradient bg-clip-text cursor-pointer ">
            <Link to="hero" smooth={true} duration={500} >ATO</Link>
              </h1>
          </div>

          <div className="font-bold text-gradient bg-clip-text hidden md:block">
            <ul className="flex space-x-4 ">
              <li>
                <Link to="hero" smooth={true} duration={500} className="hover:text-gray-200 cursor-pointer">Home</Link>
              </li>
              <li>
                <Link to="services" smooth={true} duration={500} className="hover:text-gray-300 cursor-pointer">Services</Link>
              </li>
              <li>
                <Link to="portfolio" smooth={true} duration={500} className="hover:text-gray-200 cursor-pointer">Portfolio</Link>
              </li>
           
              <li>
                <Link to="testimonials" smooth={true} duration={500} className="hover:text-gray-200 cursor-pointer">Testimonials</Link>
              </li>
              <li>
                <Link to="ContactForm" smooth={true} duration={500} className="hover:text-gray-200 cursor-pointer">Contact</Link>
              </li>
              <li>
                <Link to="About" smooth={true} duration={500} className="hover:text-gray-200 cursor-pointer">About Us</Link>
              </li>
              <li>
                <FontAwesomeIcon icon={faGlobe}/>
                <a href="#" className=" hover:text-gray-200">EN</a>
              </li>
              <li>
                <FontAwesomeIcon icon={faSearch}/> 
              </li>
              <li>
                <FontAwesomeIcon icon={faInstagram}/>
              </li>
              <li>
                <FontAwesomeIcon icon={faYoutube}/>
              </li>
              <li>
                <FontAwesomeIcon icon={faFacebookF}/>
              </li>
            </ul>
          </div>
          <div className="md:hidden">
            <button
              className="text-white focus:outline-none"
              onClick={toggleNavbar}
            >
              <svg
                className="h-6 w-6"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {isOpen ? (
                  <path d="M6 18L18 6M6 6l12 12"></path>
                ) : (
                  <path d="M4 6h16M4 12h16m-7 6h7"></path>
                )}
              </svg>
            </button>
          </div>
        </div>
        {isOpen && (
          <div className="md:hidden">
            <ul className="mt-2">
              <li>
                <Link to="hero" smooth={true} duration={500} className="block text-white hover:text-gray-300 py-2 cursor-pointer">Home</Link>
              </li>
              <li>
                <Link to="services" smooth={true} duration={500}  offset={-64}  className="block text-white hover:text-gray-300 py-2 cursor-pointer">Services</Link>
              </li>
              <li>
                <Link to="portfolio" smooth={true} duration={500} className="block text-white hover:text-gray-300 py-2 cursor-pointer">Portfolio</Link>
              </li>
           
              <li>
                <Link to="testimonials" smooth={true} duration={500} className="block text-white hover:text-gray-300 py-2 cursor-pointer">Testimonials</Link>
              </li>
              <li>
                <Link to="footer" smooth={true} duration={500} className="block text-white hover:text-gray-300 py-2 cursor-pointer">Contact</Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
