import React from 'react'
import ab from '../../assets/img/About.png'
import pstr from '../../assets/img/creative-marketing-plan.png'

const About = () => {
    return (
        <div className=' min-h-screen '>
            <div className='container mx-auto'>
                <div className='flex justify-center '>
                    <h1 className='font-bold text-5xl'>About Us</h1>
                </div>

                <div className='flex flex-col-2 m-8 p-8 justify-between'>

                    <div className='pt-2 w-2/3'>
                        <h1 className='font-bold text-5xl w-2/3 '>
                            ato Creative! Our Name Has a Story.
                        </h1>
                        <p className='m-8'>
                            Welcome to ato Creative, a unique agency founded by three innovative minds.
                            The term 'ato' holds significance in the Igbo language, meaning 'three',
                            representing our founders. In Amharic, 'Ato' is a respectful term for a man.
                            This mirrors our approach to creativity and design, emphasizing humility,
                            respect, and collaboration. Join us at Ato Creative for projects fueled by cultural richness, collaborative effort,
                            and boundless creativity.
                        </p>

                        <button className='bg-[#FD5F20] p-4 rounded-lg text-white m-4'>Watch Our Story</button>
                    </div>

                    <div className='w-1/3 flex  item-justify-right'>
                        <img src={ab} className='pl-6 mt-8 h-80 w-100 object-cover' />
                    </div>
                </div>
                <div className='flex flex-col-2 justify-between m-8 pl-8 pr-8 gap-8'>
                    <div className='w-1/2'>
                        <h1 className='text-5xl font-bold '>Yes We Are</h1>
                        <p className='m-8 flex gap-8 '>
                            <ul className='w-2/3 text-justify'>
                                <li >
                                    <h1 className='font-bold'> Client-Centric</h1>
                                    <p className='text-gray-500'>
                                        Our clients' triumphs are our own. We tailor our services to their unique needs.
                                    </p>
                                </li>
                                <li>
                                    <h1 className='font-bold'> Transparent & integrate</h1>
                                    <p className='text-gray-500'>
                                        We operate with honesty and ethical behavior, fostering trust.
                                    </p>
                                </li>
                                <li>
                                    <h1 className='font-bold'> Creative & Innovative</h1>
                                    <p className='text-gray-500'>
                                        We push boundaries with fresh ideas, driving success.
                                    </p>
                                </li>
                                <li>
                                    <h1 className='font-bold'> Data-Driven</h1>
                                    <p className='text-gray-500'>
                                        Informed decisions from data analysis lead to optimized campaigns.
                                    </p>
                                </li>
                                <li>
                                    <h1 className='font-bold'> Results-Oriented</h1>
                                    <p className='text-gray-500'>
                                        We focus on measurable outcomes for brand growth and revenue.
                                    </p>
                                </li>
                            </ul>
                        </p>
                    </div>

                    <div className='w-1/2'>
                        <h1 className='text-5xl font-bold '>
                            We take time to understand your Business
                        </h1>
                        <p className='text-gray-600 m-8'>
                            We help you find the right Niche for your business, create a Messaging that resonates with your Audience, help you structure a solution that’s appealing to your Audience
                            We then help you create different pitches to offer your solution to your Target Audience
                        </p>
                        <h1 className='text-3xl font-bold'>Act Now - Your Future Awaits!</h1>
                        <form >
                            <div className='flex flex-col-2'>
                                <div className='w-1/2' >
                                    <div className='m-2'>
                                        <input
                                            type='text'
                                            name='name'
                                            id='name'
                                            placeholder='Name'
                                            className="w-full  border-2 p-1 border-[#FD5F20] rounded focus:border-[#FD5F20] focus:outline-none focus:ring-2 focus:ring-[#FD5F20] hover:border-[#FD5F20] active:border-[#FD5F20]"
                                        />
                                    </div>

                                    <div className='m-2'>
                                        <input
                                            type='text'
                                            name='phone'
                                            id='phone'
                                            placeholder='Phone'
                                            className="w-full border-2 p-1 border-[#FD5F20] rounded focus:border-[#FD5F20] focus:outline-none focus:ring-2 focus:ring-[#FD5F20] hover:border-[#FD5F20] active:border-[#FD5F20]"
                                        />
                                    </div>
                                </div>
                                <div className='w-1/2'>
                                    <div className='m-2'>
                                        <select
                                            className="w-full text-gray-700 border-2 border-[#FD5F20] rounded-lg p-2 bg-white focus:border-[#FD5F20] focus:outline-none focus:ring-2 focus:ring-[#FD5F20] hover:border-[#FD5F20] active:border-[#FD5F20] shadow-lg"
                                        >
                                            <option className='text-gray-700' disabled selected>
                                                Select Your Industry
                                            </option>
                                            <option className="text-gray-700 border-b border-gray-800 py-2">Solopreneurs</option>
                                            <option className="text-gray-700 border-b border-gray-200 py-2">Personal Training</option>
                                            <option className="text-gray-700 border-b border-gray-200 py-2">Beauty Spa</option>
                                            <option className="text-gray-700 border-b border-gray-200 py-2">Bakery & Spa</option>

                                            <option className="text-gray-700 border-b border-gray-200 py-2">Bakery & Cafe</option>
                                            <option className="text-gray-700 border-b border-gray-200 py-2">Consultancy</option>
                                            <option className="text-gray-700 border-b border-gray-200 py-2">Coaching Center</option>
                                            <option className="text-gray-700 border-b border-gray-200 py-2">Boutique Services</option>
                                            <option className="text-gray-700 border-b border-gray-200 py-2">Clinics & Medicals</option>
                                            <option className="text-gray-700 border-b border-gray-200 py-2">Other Services</option>
                                            









                                        </select>


                                    </div>
                                    <div className='m-2'>


                                        <input
                                            type='email'
                                            name='email'
                                            id='email'
                                            placeholder='Email'
                                            className="w-full  border-2 p-1 border-[#FD5F20] rounded focus:border-[#FD5F20] focus:outline-none focus:ring-2 focus:ring-[#FD5F20] hover:border-[#FD5F20] active:border-[#FD5F20]"
                                        />
                                    </div>


                                </div></div>
                            <div className=''>
                                <button type='submit'
                                    className='bg-[#FD5F20] rounded-lg p-2 text-white font-bold w-full '
                                >
                                    Get Free Consultation
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
                <div className='flex flex-col-2 m-8 p-8 justify-between'>
                    <div className='w-1/2'>
                        <h1 className='text-5xl font-bold m-4 underline decoration-[#FD5F20] '>Yes We Do </h1>
                        <ul className='m-8 text-lg font-bold justify-end flex-1 gap-2 text-right'>
                            <li className='cursor-pointer p-2'>
                                Advertising & Branding
                            </li>
                            <li className='cursor-pointer p-2 '>
                                Strategy Development
                            </li>
                            <li className='cursor-pointer p-2'>
                                Digital Services
                            </li>
                            <li className='cursor-pointer p-2'>
                                Video and Audio Production
                            </li>
                            <li className='cursor-pointer p-2'>
                                Media Planning and Buying
                            </li>
                            <li className='cursor-pointer p-2'>
                                Account Management
                            </li>
                        </ul>
                    </div>
                    <div className='w-1/2 m-8'>
                        <video
                            className="w-full h-full m-8"
                            controls
                            autoPlay
                            muted
                            poster={pstr} // Replace with the path to your poster image

                            src="/path/to/your/video.mp4" // Replace with the actual path to the video file
                        />

                    </div>

                </div>
            </div>
        </div >
    )
}

export default About