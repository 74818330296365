import React from "react";
import user from '../assets/img/Mask group.png'
import icon1 from '../assets/Icons/pngegg (17) 1.png'
import icon2 from '../assets/Icons/pngegg (19) 1.png'
import icon3 from '../assets/Icons/pngegg (20) 1.png'
import icon4 from '../assets/Icons/pngegg (21) 1.png'
import icon5 from '../assets/Icons/pngegg (22) 1.png'



const ClientFeedback = () => {


    return (

        <div className="pt-4 relative min-h-screen">
            <div className=" py-8 px-4 text-center flex items-center justify-center">
                <div className="flex-grow border-t border-gray-900 mx-4"></div>
                <h1 className="text-4xl font-bold text-gray-900">Our Satisfied Clients</h1>
                <div className="flex-grow border-t border-gray-900 mx-4"></div>
            </div>
            <div className="container max-w-6xl px-5 mx-auto   my-10 perspective-1000">
                <div className="grid gap-3 md:grid-cols-5 sm:grid-cols-2 lg:grid-cols-5 p-2">
                    <img className=" w-full h-full object-cover transform transition duration-500 hover:scale-125 hover:rotate-3 hover:shadow-xl " src={icon3} />
                    <img className=" w-full h-full object-cover transform transition duration-500 hover:scale-125 hover:rotate-3 hover:shadow-xl" src={icon4} />
                    <img className=" w-full h-full object-cover transform transition duration-500 hover:scale-125 hover:rotate-3 hover:shadow-xl " src={icon5} />
                    <img className=" w-full h-full object-cover transform transition duration-500 hover:scale-125 hover:rotate-3 hover:shadow-xl " src={icon2} />
                    <img className=" w-full h-full object-cover transform transition duration-500 hover:scale-125 hover:rotate-3 hover:shadow-xl " src={icon1} />
                </div>


            </div>


            <div className=" py-8 px-4 text-center flex items-center justify-center">
                <div className="flex-grow border-t border-gray-900 mx-4"></div>
                <h1 className="text-4xl font-bold text-gray-900">What they say we are</h1>
                <div className="flex-grow border-t border-gray-900 mx-4"></div>
            </div>
            <div className="container max-w-6xl px-5 mx-auto   my-10">
                <div className="grid gap-8 md:grid-cols-4 sm:grid-cols-2 lg:grid-cols-4 p-2">
                    <div className="flex-1 justify-between text-justify ">
                        <div className="px-8 p-4">

                            <img
                                className=" h-20 w-20  rounded-full object-cover  justify-center 
                                 inset-0   opacity-90
                                border-4 border-[#FD5F20] shadow-xl"
                                src={user}
                                alt="Defar"
                            />

                        </div>
                        <p>“Working with <strong>Ato Creative</strong> was a game-changer for our online presence. 
                            They transformed our outdated website into a sleek, modern platform that perfectly represents our brand.
                             The team was responsive, creative, and truly understood our vision. We couldn't be happier with the results!.”</p>
                             <p className="m-8 font-semibold text-gray-500 "><i> </i></p>
                             <p className="text-md font-semibold text-orange-500 m-4 mb-2 p-2 cursor-pointer  items-center tracking-wider drop-shadow-lg relative group shadow-xl">
<i> John Doe, CEO of TechStart</i>
<span className="absolute left-0 bottom-0 h-1 bg-orange-500 transition-all duration-300 ease-in-out w-0 group-hover:w-full shadow-5xl"></span>
</p>
                    </div>


                    <div className="flex-1 justify-between text-justify ">
                        <div className="px-8 p-4">

                            <img
                                className=" h-20 w-20  rounded-full object-cover  justify-center 
                                 inset-0   opacity-90
                                border-4 border-[#FD5F20] shadow-xl"
                                src={user}
                                alt="Defar"
                            />

                        </div>
                        <p>“The video production team at <strong> Ato Creative</strong> exceeded our expectations.
                             They captured our story beautifully and created a promotional video that has already boosted our engagement.
                            Their creativity and professionalism made the entire process seamless.
                             We highly recommend them for any video needs.”</p>
                             <p className="text-md font-semibold text-orange-500 m-4 mb-2 p-2 cursor-pointer  items-center tracking-wider drop-shadow-lg relative group shadow-xl">
<i> Jane Smith, Marketing Director at MarketMakers</i>
<span className="absolute left-0 bottom-0 h-1 bg-orange-500 transition-all duration-300 ease-in-out w-0 group-hover:w-full shadow-5xl"></span>
</p>
                    </div>
                    <div className="flex-1 justify-between text-justify ">
                        <div className="px-8 p-4">

                            <img
                                className=" h-20 w-20  rounded-full object-cover  justify-center 
                                 inset-0   opacity-90
                                border-4 border-[#FD5F20] shadow-xl"
                                src={user}
                                alt="Defar"
                            />

                        </div>
                        <p>“As a small business, we needed a strong brand identity to stand out. 
                            <strong> Ato Creative </strong> delivered just that. 
                            From logo design to brand messaging, they captured the essence of our company and created a cohesive brand strategy. Their attention to detail and dedication to our project was impressive.”</p>
                            Michael Brown, Founder of Crafty Creations
                            <p className="text-md font-semibold text-orange-500 m-4 mb-2 p-2 cursor-pointer  items-center tracking-wider drop-shadow-lg relative group shadow-xl">
<i> Michael Brown, Founder of Crafty Creations </i>
<span className="absolute left-0 bottom-0 h-1 bg-orange-500 transition-all duration-300 ease-in-out w-0 group-hover:w-full shadow-5xl"></span>
</p>
                    </div>
                    <div className="flex-1 justify-between text-justify ">
                        <div className="px-8 p-4">

                            <img
                                className=" h-20 w-20  rounded-full object-cover  justify-center 
                                 inset-0   opacity-90
                                border-4 border-[#FD5F20] shadow-xl"
                                src={user}
                                alt="Defar"
                            />

                        </div>
                        <p>“Our partnership with <strong> Ato Creative</strong> has been instrumental in growing our online presence. Their digital marketing strategies have helped us reach a wider audience and increase our customer base. The team's expertise in SEO, social media, and content marketing has been invaluable. They truly care about their clients' success.”</p>
                  
<p className="text-md font-semibold text-orange-500 m-4 mb-2 p-2 cursor-pointer  items-center tracking-wider drop-shadow-lg relative group shadow-xl">
<i> Emily White, COO of Fitness Fusion </i>
<span className="absolute left-0 bottom-0 h-1 bg-orange-500 transition-all duration-300 ease-in-out w-0 group-hover:w-full shadow-5xl"></span>
</p>

                    </div>
                </div>


            </div>
        </div>

    );
}
export default ClientFeedback