import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData); // Handle form submission logic here
  };

  return (
    <div className="max-w-full  min-h-[calc(100vh-5rem)] justify-between mx-auto   ">
      <div className='flex flex-col-2 ml-8 mr-8 pr-8'>
        <div className='flex flex-col flex-1 m-4'>
          <h1 className='text-5xl font-bold mb-4 '> Would You Like To Start A Project With Us?</h1>
          Uncover and define the fundamental qualities and values of your brand with the guidance of our team of experts. Our seasoned professionals will work closely with you to reveal the true essence of your brand, allowing you to better understand and communicate its unique identity to your audience. Let us help you unlock the full potential of your brand with our comprehensive discovery process

          <div className="pt-5 font-bold flex items-center justify-start">
            <div className="w-1/2">
              <p className="flex flex-1">
                +251 11 554 4901 / info@atocreative.et<br />
                Bedesta Building, 4th floor, Addis Ababa, Ethiopia<br />
                Copyright ©2024 ato creative
              </p>
            </div>
          </div>
        </div>
        <div className='flex flex-col flex-1'>
          <h2 className="text-2xl font-bold mb-4 w-full">Contact Us</h2>
          <form onSubmit={handleSubmit} >

            <div className='flex flex-col-2 gap-4' >


              <div className='w-1/2'>
                <div className="mb-4">

                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="appearance-none border border-2 border-[#FD5F20] rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4">

                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    className="appearance-none border border-2 border-[#FD5F20] rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Phone"
                    value={formData.Phone}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4">

                  <input
                    type="number"
                    step={2}
                    id="budget"
                    name="budget"
                    className="appearance-none border border-2 border-[#FD5F20] rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Budget"
                    value={formData.budget}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className='w-1/2'>
                <div className="mb-4">

                  <input
                    type="text"
                    id="company"
                    name="company"
                    className="appearance-none border border-2 border-[#FD5F20] rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Company"
                    value={formData.company}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="appearance-none border border-2 border-[#FD5F20] rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="text"
                    id="metrics"
                    name="metrics"
                    className="appearance-none border border-2 border-[#FD5F20] rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Prefered Contact Metrics"
                    value={formData.metrics}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="mb-6">

              <textarea
                id="message"
                name="message"
                className="appearance-none border border-2 border-[#FD5F20] rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32 resize-none"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex items-center justify-between ">
              <button
                type="submit"
                className="bg-[#FD5F20] hover:bg-[#FD5F20] w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <div className="m-8  flex flex-col-1 justify-center">
          <div className='w-full m-1'>
            <h3 className="text-2xl font-semibold m-4 p-2 flex justify-center">Find Us</h3>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13255.060213575276!2d38.79133532530632!3d8.99486305900315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b8584b0f1294d%3A0x78c5c25e5067a736!2sBole%20Medhanialem%20Church!5e0!3m2!1sen!2set!4v1722943168146!5m2!1sen!2set"  // Replace with your actual embed code

              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="rounded-lg shadow-lg w-full h-60 items-center"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;


