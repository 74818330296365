import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-12  mt-auto"> {/* Added mt-auto to push the footer to the bottom */}
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl font-semibold mb-4">About Us</h3>
            <p className="text-gray-400">
              Ato Creatives is a creative marketing agency dedicated to helping startups succeed online.
            </p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
            <p className="text-gray-400">
              Email: info@atocreatives.com<br />
              Phone: +1234567890<br />
              Address: 123 Street, Addis Ababa, Ethiopia
            </p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4">Follow Us</h3>
            <div className="flex space-x-4 text-[#FD5F20]">
              <a href="#" className="text-[#FD5F20] hover:text-white text-3xl">
                <i className="fab fa-facebook-f"><FontAwesomeIcon icon={faFacebookF}/></i>
              </a>
              <a href="#" className="text-[#FD5F20] text-3xl hover:text-white">
                <i className="fab fa-twitter"><FontAwesomeIcon icon={faTwitter}/> </i>
              </a>
              <a href="#" className="text-3xl text-[#FD5F20] hover:text-white">
                <i className="fab fa-instagram"><FontAwesomeIcon icon={faInstagram} /></i>
              </a>
              <a href="#" className="text-3xl text-[#FD5F20] hover:text-white">
                <i className="fab fa-linkedin-in"><FontAwesomeIcon icon={faLinkedinIn}/> </i>
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center">
          <p>&copy; {new Date().getFullYear()} Ato Creatives Agency. All rights reserved. </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
