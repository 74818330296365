import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import ServicesSection from './components/ServicesSection';
import Footer from './components/Footer';
import TestimonialSection from './components/TestimonialSection';
import Porti from './components/port';
import ClientFeedback from './components/ClientFeedback';
import ContactForm from './components/Contact/ContactForm';
import About from './components/Pages/About';
import BackToTop from './components/BackToTop';

const App = () => {
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const Section = ({ children, id }) => {
    const { ref, inView } = useInView({ threshold: 0.2, triggerOnce: true });

    return (
      <motion.section
        id={id}
        ref={ref}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        variants={sectionVariants}
        transition={{ duration: 0.9, easeInOutBack: 'cubic-bezier(0.68, -0.55, 0.265, 1.55)' }}
        className="min-h-[calc(100vh-5rem)]"
      >
        {children}
      </motion.section>
    );
  };

  return (
    <div className='min-h-screen'>
      <Navbar className="m-h-16" />
      <div className="min-h-80]">
        <Section id="hero">
          <HeroSection />
        </Section>
        <Section id="services">
          <ServicesSection />
        </Section>
        <Section id="portfolio">
          <Porti />
        </Section>
        <Section id="feedback">
          <ClientFeedback />
        </Section>
        <Section id="testimonials">
          <TestimonialSection />
        </Section>
        <Section id="ContactForm" className="bg-[rgba(253,95,32,0.25)]">
          <ContactForm />
        </Section>
        <Section id="About">
          <About />
        </Section>
      </div>
      <Footer />
      <BackToTop />
    </div>
  );
};

export default App;
