import React, { useEffect, useState, useRef } from 'react';
import customer from '../assets/img/customer.png';
import abstracts from '../assets/img/abstracts.jpg';
import ato from '../assets/img/ato.jpg';
import bkg from '../assets/img/giphy.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKiwiBird, faFish } from '@fortawesome/free-solid-svg-icons';

const HeroSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  const [positions, setPositions] = useState({
    bee1: { top: '10%', left: '10%' },
    bee2: { top: '80%', left: '80%' },
    butterfly1: { top: '10%', left: '80%' },
    butterfly2: { top: '80%', left: '10%' },
  });

  useEffect(() => {
    const timeout = setTimeout(() => setIsVisible(true), 1000); // Adjust delay as needed
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const moveIcons = () => {
      setPositions({
        bee1: { top: `${Math.random() * 100}%`, left: `${Math.random() * 100}%` },
        bee2: { top: `${Math.random() * 100}%`, left: `${Math.random() * 100}%` },
        butterfly1: { top: `${Math.random() * 100}%`, left: `${Math.random() * 100}%` },
        butterfly2: { top: `${Math.random() * 100}%`, left: `${Math.random() * 100}%` },
      });
    };

    const interval = setInterval(moveIcons, 3000); // Move every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount


  }, []);

  const [words, setWords] = useState([]);
  const [text, setText] = useState(' We are a creative marketing agency dedicated to helping your business succeed.');

  useEffect(() => {
    setWords(text.split(' '));
  }, [text]);

  useEffect(() => {
    const handleFocus = () => {
      setWords(words => [...words]); // Re-trigger the animation
    };

    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

    


  

  return (
    <div className="max-h-screen min-h-screen relative overflow-hidden">
      {/* Background Image */}

      {/* <img
        className="object-cover w-full h-screen absolute top-0  left-0 z-0"
        src={abstracts}
        alt="Ato Image"
      /> */}
<div className="relative w-full h-screen overflow-hidden">
      <img
        src={bkg}
        className="object-cover w-full h-screen absolute top-0 left-0 z-0"
        frameBorder="0"
        allowFullScreen
        title="Giphy Embed"
      />




<img
  className="object-cover w-full h-screen absolute top-0 left-0 z-0"
src={`${process.env.PUBLIC_URL}/assets/img/giphy.webp`} alt="Background" />

    </div>
      {/* Content */}
      <div className="container mx-auto px-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">

        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="lg:w-1/2 z-10">
            <h1
              className={`text-[#FD5F20] mb-8 text-4xl lg:text-6xl font-bold transition-all duration-1000 ease-in-out ${isVisible
                ? 'opacity-100 transform-gpu translate-y-0'
                : 'opacity-0 transform-gpu translate-y-full'
                }`}
            >
              Welcome to Ato Creatives Agency
            </h1>
            {words.map((word, index) => (
        <p
          key={index}
          className={`text-xl font-bold  text-gray-300 opacity-0 animate-fadeIn inline-block m-1`}
          style={{ animationDelay: `${index * 0.3}s` }}
        >
          {word}
        </p>
      ))}
            <div className='pt-8 animate-fadeIn delay-3500' >
              <a
                href="#"
                className="bg-[#FD5F20] hover:bg-[#FD5F20] text-white font-semibold py-2 px-4 p-4 rounded-lg   mr-4"
              >
                Learn More
              </a>
              <a
                href="#"
                className="bg-transparent border-2 border-[#FD5F20] hover:border-gray-300 text-white font-semibold py-2 px-4 rounded"
              >
                Contact Us
              </a>
            </div>
          </div>
          



          <div className="relative lg:w-1/2 mt-6 lg:mt-0 text-cyan-500 z-10">
            <img
              src={ato}
              alt="Agency Image"
              className="w-full rounded-lg shadow-lg h-1/4"
            />
            {/* Animated Icons */}
            <div className="absolute bee animate-bee" style={{ top: positions.bee1.top, left: positions.bee1.left }}>
              <FontAwesomeIcon icon={faKiwiBird} size="3x" />
            </div>
            <div className="absolute butterfly animate-butterfly" style={{ top: positions.butterfly1.top, left: positions.butterfly1.left }}>
              <FontAwesomeIcon icon={faFish} size="4x" />
            </div>
            <div className="absolute bee animate-bee2 text-green-400" style={{ top: positions.bee2.top, left: positions.bee2.left }}>
              <FontAwesomeIcon icon={faKiwiBird} size="4x" />
            </div>
            <div className="absolute butterfly animate-butterfly text-yellow-600" style={{ top: positions.butterfly2.top, left: positions.butterfly2.left }}>
              <FontAwesomeIcon icon={faFish} size="4x" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
